import React, { useState, useEffect, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Spinner,
  Button,
  useColorModeValue,
  VStack,
  HStack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Input,
  InputGroup,
  InputLeftAddon,
  Badge,
  Flex,
  Avatar,
  AvatarBadge,
  Tooltip,
  Collapse,
  ScaleFade,
  BoxProps,
} from '@chakra-ui/react';
import { isValidMotionProp, motion, MotionProps } from 'framer-motion';
import { FiUser, FiMessageCircle, FiCalendar, FiHardDrive, FiAlertCircle, FiLock, FiFileText, FiKey, FiInfo, FiRefreshCw, FiClock, FiHash, FiShield, FiEye, FiEyeOff, FiTrash2, FiPlus } from 'react-icons/fi';
import { useAuth } from '../../contexts/AuthContext';
import { get, post, del } from '../../utils/apiUtils';
import DynamicHtmlContent  from '../../utils/dynamicHtmlContent';

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

interface TranscriptData {
  filename: string;
  user_id: string;
  user_name: string;
  channel_id: string;
  closer_id: string;
  closer_name: string;
  createdAt: string;
  type: string;
  file_size: number;
  bypass_key: string | null;
  bypass_key_expiration: string | null;
  bypass_uses_left: number | null;
  reason: string;
}

interface DiscordUserData {
  id: string;
  username: string;
  avatar: string;
}

const ADMIN_ROLES = ["597495855100067850", "596402057758375966", "671905273446924348"];
const STAFF_ROLES = ["597496371729268746"];
const RESTRICTED_CATEGORIES = ["COMPRAS", "REPORTESSTAFF", "CUENTA", "APELACION"];

export default function TicketLog() {
  const { fileName } = useParams<{ fileName: string }>();
  const [transcriptData, setTranscriptData] = useState<TranscriptData | null>(null);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [bypassKeyUsed, setBypassKeyUsed] = useState(false);
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(true);
  const [userData, setUserData] = useState<DiscordUserData | null>(null);
  const [closerData, setCloserData] = useState<DiscordUserData | null>(null);
  const { isAuthenticated, discordId, login } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [expirationTime, setExpirationTime] = useState<number | null>(null);
  const [maxUses, setMaxUses] = useState<number | null>(null);
  const [inputBypassKey, setInputBypassKey] = useState('');
  const [showAdminPanel, setShowAdminPanel] = useState(false);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('orange.200', 'orange.700');
  const infoBgColor = useColorModeValue('orange.50', 'gray.900');
  const headingColor = useColorModeValue('orange.600', 'orange.300');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const cardBorderColor = useColorModeValue('gray.200', 'gray.600');

  const fetchDiscordUserData = async (userId: string) => {
    try {
      const response = await get<DiscordUserData>(`discord-user-data/${userId}`);
      if (response.success) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching Discord user data:", error);
    }
    return null;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      
      const metadataResponse = await get<TranscriptData[]>(`transcripts/search?filename=${fileName}`);
      if (metadataResponse.success && metadataResponse.data && metadataResponse.data.length > 0) {
        setTranscriptData(metadataResponse.data[0]);
        
        const userData = await fetchDiscordUserData(metadataResponse.data[0].user_id);
        const closerData = await fetchDiscordUserData(metadataResponse.data[0].closer_id);
        setUserData(userData ?? null);
        setCloserData(closerData ?? null);
      }

      if (discordId) {
        const rolesResponse = await get<{ roles: string[] }>(`user-roles/${discordId}`);
        if (rolesResponse.success) {
          setUserRoles(rolesResponse.data?.roles || []);
        } else {
          setError('No se pudieron obtener los roles del usuario. Por favor, inténtalo de nuevo más tarde.');
        }
      }
    } catch (err) {
      setError('Error al obtener los datos del archivo');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && discordId) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [fileName, discordId, isAuthenticated]);

  useEffect(() => {
    const checkPermission = () => {
      if (!transcriptData || !discordId) {
        setHasPermission(false);
        return;
      }

      const isOwner = discordId === transcriptData.user_id;
      const isStaff = userRoles.some(role => STAFF_ROLES.includes(role));
      const isAdmin = userRoles.some((role) => ADMIN_ROLES.includes(role));
      const isRestrictedCategory = RESTRICTED_CATEGORIES.includes(transcriptData.type);
    
      setIsAdmin(isAdmin);
      setHasPermission(isOwner || (isStaff && !isRestrictedCategory) || isAdmin || bypassKeyUsed);
    };

    checkPermission();
  }, [transcriptData, discordId, userRoles, bypassKeyUsed]);

  useEffect(() => {
    const loadHtmlContent = async () => {
      if (hasPermission) {
        setIsTranscriptLoading(true);
        try {
          const htmlResponse = await get<string>(`transcript/${fileName}`, { responseType: 'text' });
          if (htmlResponse.success) {
            setHtmlContent(htmlResponse.data || '');
          }
        } catch (err) {
          setError('Error al cargar el contenido del transcript');
          console.error(err);
        } finally {
          setIsTranscriptLoading(false);
        }
      }
    };

    loadHtmlContent();
  }, [hasPermission, fileName]);

  const formatDate = (dateString: string) => {
    if (!dateString) return 'Fecha no disponible';
    
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Fecha no disponible';
    }
    
    return date.toLocaleString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    });
  };

  const handleBypassKeySubmit = async () => {
    try {
      if (!fileName) {
        throw new Error("Filename is not available");
      }
      const response = await post(`transcript/${fileName}/bypass/validate`, {
        bypass_key: inputBypassKey,
      });

      if (response.success) {
        setBypassKeyUsed(true);
        setHasPermission(true);
        toast({
          title: "Acceso concedido",
          description: "¡Token válido! Ahora puedes ver el transcript.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        await fetchData();
      } else {
        throw new Error(response.error || "¡Token inválido!");
      }
    } catch (error) {
      console.error("Error validating bypass key:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "¡Token inválido! Por favor, inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const generateRandomHex = (length: number): string => {
    let result = '';
    const characters = '0123456789ABCDEF'; 

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const generateBypassKey = (): string => {
    const randomPart = generateRandomHex(16); 
    const randomNumbers = Math.floor(1000000 + Math.random() * 9000000).toString(); 
    const encryptedCode = 'M1N314T1N0';

    const placeEncryptedCodeAtStart = Math.random() < 0.5; 

    return placeEncryptedCodeAtStart
        ? `${encryptedCode}${randomPart}${randomNumbers}`
        : `${randomPart}${encryptedCode}${randomNumbers}`; 
};

  const handleCreateBypassKey = async () => {
    const generatedKey = generateBypassKey();
    try {
      const response = await post(`transcript/${fileName}/bypass`, {
        bypass_key: generatedKey,
        expiration_time_in_hours: expirationTime,
        max_uses: maxUses,
      });

      if (response.success) {
        toast({
          title: "Token creado",
          description: `Clave: ${generatedKey}\nExpiración: ${expirationTime} horas\nUsos máximos: ${maxUses || 'Sin límite'}`,
          status: "success",
          duration: 10000,
          isClosable: true,
        });
        onClose();
        await fetchData();
      } else {
        throw new Error(response.error || "Error al crear el token, contacta con Gasthi");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "No se pudo crear el token de acceso. Por favor, inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveBypassKey = async () => {
    try {
      const response = await del(`transcript/${fileName}/bypass`);

      if (response.success) {
        toast({
          title: "Token eliminado",
          description: "El token ha sido eliminado correctamente.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        await fetchData();
      } else {
        throw new Error(response.error || "Error al eliminar el toke de acceso");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "No se pudo eliminar el token de acceso. Por favor, inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Container maxW="container.xl" py={8}>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          bg={bgColor}
          borderRadius="xl"
          overflow="hidden"
          boxShadow="xl"
          border="1px"
          borderColor={borderColor}
          p={8}
        >
          <VStack spacing={6} align="center">
            <Spinner size="xl" color="orange.500" thickness="4px" speed="0.65s" />
            <Heading as="h2" size="xl" textAlign="center" color={headingColor}>
              Cargando datos...
            </Heading>
            <Text fontSize="lg" textAlign="center" color={textColor}>
              Por favor, espere mientras verificamos su acceso.
            </Text>
          </VStack>
        </MotionBox>
      </Container>
    );
  }

  if (!fileName) {
    return <ErrorMessage
      icon={FiAlertCircle}
      title="Error"
      message="No se pudo cargar el ticket. Nombre de archivo no disponible."
      buttonText="Volver a la página principal"
      onButtonClick={() => navigate('/')}
    />;
  }

  if (!isAuthenticated) {
    return <ErrorMessage
      icon={FiLock}
      title="Acceso Restringido"
      message="Debes estar logeado y tener permisos para ver este ticket."
      buttonText="Iniciar sesión"
      onButtonClick={login}
    />;
  }

  if (error || !hasPermission) {
    return <ErrorMessage
      icon={error ? FiAlertCircle : FiLock}
      title={error ? "Error" : "Acceso Denegado"}
      message={
        error
          ? "Ha ocurrido un error al cargar el ticket. Por favor, inténtalo de nuevo más tarde."
          : `No posees permisos para ver la categoría ${transcriptData?.type}.`
      }
      buttonText="Volver a la página principal"
      onButtonClick={() => navigate('/')}
    >
      <VStack spacing={4} align="stretch" width="100%" maxWidth="400px" mt={4}>
        <InputGroup>
          <InputLeftAddon children={<Icon as={FiKey} />} />
          <Input
            placeholder="Ingresa el token de acceso, por favor"
            value={inputBypassKey}
            onChange={(e) => setInputBypassKey(e.target.value)}
          />
        </InputGroup>
        <Button
          colorScheme="orange"
          
          onClick={handleBypassKeySubmit}
          isDisabled={!inputBypassKey}
          leftIcon={<FiKey />}
        >
          Usar token
        </Button>
      </VStack>
    </ErrorMessage>;
  }

  return (
    <Container maxW="container.xl" py={8}>
      <MotionBox
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        bg={bgColor}
        borderRadius="xl"
        overflow="hidden"
        boxShadow="xl"
        border="1px"
        borderColor={borderColor}
      >
        <Box bg="orange.500" p={6}>
          <VStack spacing={2} align="center">
            <Icon as={FiFileText} w={12} h={12} color="white" />
            <Heading as="h2" size="lg" color="white" textAlign="center">
              Ticket Log: {fileName}
            </Heading>
          </VStack>
        </Box>
        {transcriptData && (
          <Box p={6} bg={infoBgColor}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              <UserInfoCard
                userData={userData}
                userName={transcriptData.user_name}
                userId={transcriptData.user_id}
                label="Usuario"
              />
              <UserInfoCard
                userData={closerData}
                userName={transcriptData.closer_name}
                userId={transcriptData.closer_id}
                label="Cerrado por"
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6} mt={6}>
              <StatCard
                icon={FiMessageCircle}
                label="ID Canal"
                value={transcriptData.channel_id || 'N/A'}
              />
              <StatCard
                icon={FiCalendar}
                label="Creado el"
                value={formatDate(transcriptData.createdAt)}
              />
              <StatCard
                icon={FiHardDrive}
                label="Tamaño del archivo"
                value={`${((transcriptData.file_size || 0) / 1024).toFixed(2)} KB`}
              />
              <StatCard
                icon={FiInfo}
                label="Categoría"
                value={transcriptData.type || 'N/A'}
              />
            </SimpleGrid>
            
            {/* New Reason Card */}
            <Box
              mt={6}
              bg={cardBgColor}
              borderRadius="lg"
              p={4}
              boxShadow="md"
              border="1px"
              borderColor={cardBorderColor}
            >
              <VStack align="start" spacing={2}>
                <HStack>
                  <Icon as={FiFileText} color="orange.500" />
                  <Text fontSize="lg" fontWeight="bold">Razón del Ticket</Text>
                </HStack>
                <Text fontSize="md" color={textColor}>
                  {transcriptData.reason || 'No se proporcionó una razón.'}
                </Text>
              </VStack>
            </Box>

            {/* Admin Panel */}
            {isAdmin && (
              <Box mt={4}>
                <Button
                  leftIcon={showAdminPanel ? <FiEyeOff /> : <FiEye />}
                  colorScheme="purple"
                  onClick={() => setShowAdminPanel(!showAdminPanel)}
                  mb={4}
                  variant="outline"
                  _hover={{ bg: 'purple.50' }}
                >
                  {showAdminPanel ? "Ocultar Panel de Administración" : "Mostrar Panel de Administración"}
                </Button>
                <Collapse in={showAdminPanel} animateOpacity>
                  <Box
                    bg={cardBgColor}
                    borderRadius="lg"
                    p={4}
                    boxShadow="md"
                    border="1px"
                    borderColor={cardBorderColor}
                  >
                    <VStack align="stretch" spacing={4}>
                      <Heading size="md" display="flex" alignItems="center">
                        <Icon as={FiShield} mr={2} color="purple.500" />
                        Panel de Administración
                      </Heading>
                      {transcriptData.bypass_key ? (
                        <ScaleFade initialScale={0.9} in={true}>
                          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                            <StatCard
                              icon={FiKey}
                              label="Token"
                              value={transcriptData.bypass_key}
                            />
                            {transcriptData.bypass_key_expiration && (
                              <StatCard
                                icon={FiClock}
                                label="Expiración del token"
                                value={formatDate(transcriptData.bypass_key_expiration)}
                              />
                            )}
                            {transcriptData.bypass_uses_left !== null && (
                              <StatCard
                                icon={FiHash}
                                label="Usos restantes"
                                value={transcriptData.bypass_uses_left.toString()}
                              />
                            )}
                          </SimpleGrid>
                          <Button
                            leftIcon={<FiTrash2 />}
                            colorScheme="red"
                            onClick={handleRemoveBypassKey}
                            mt={4}
                            size="sm"
                            _hover={{ bg: 'red.50' }}
                            variant="outline"
                          >
                            Eliminar token de acceso
                          </Button>
                        </ScaleFade>
                      ) : (
                        <Button
                          leftIcon={<FiPlus />}
                          colorScheme="green"
                          onClick={onOpen}
                          size="sm"
                          variant="outline"
                          _hover={{ bg: 'green.50' }}
                        >
                          Crear nuevo token de acceso
                        </Button>
                      )}
                    </VStack>
                  </Box>
                </Collapse>
              </Box>
            )}
          </Box>
        )}
        <Box p={0}>
          {isTranscriptLoading ? (
             <Container maxW="container.xl" py={8}>
             <MotionBox
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.5 }}
               bg={bgColor}
               borderRadius="xl"
               overflow="hidden"
               boxShadow="xl"
               border="1px"
               borderColor={borderColor}
               p={8}
             >
               <VStack spacing={6} align="center">
                 <Spinner size="xl" color="orange.500" thickness="4px" speed="0.65s" />
                 <Heading as="h2" size="xl" textAlign="center" color={headingColor}>
                   Cargando datos...
                 </Heading>
                 <Text fontSize="lg" textAlign="center" color={textColor}>
                   Por favor, espere mientres cargamos el transcript :). Este proceso puede demorar si el archivo es muy grande
                 </Text>
               </VStack>
             </MotionBox>
           </Container>
          ) : (
            <DynamicHtmlContent 
            htmlContent={htmlContent}
            isLoading={isTranscriptLoading}
          />
          )}
        </Box>
      </MotionBox>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crear token de acceso</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Tiempo de expiración (horas)</FormLabel>
              <NumberInput
                max={72}
                min={1}
                value={expirationTime ?? ''}
                onChange={(valueString) => setExpirationTime(Math.floor(Number(valueString)))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Usos máximos (opcional)</FormLabel>
              <NumberInput min={1} value={maxUses || ''} onChange={(value) => setMaxUses(value === '' ? null : Number(value))}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <Text mt={4} fontSize="sm" color="gray.500">
              La clave de bypass se generará automáticamente al crear.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleCreateBypassKey}>
              Crear
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}

interface ErrorMessageProps {
  icon: React.ElementType;
  title: string;
  message: string;
  buttonText: string;
  onButtonClick: () => void;
  children?: React.ReactNode;
}

function ErrorMessage({ icon: Icon, title, message, buttonText, onButtonClick, children }: ErrorMessageProps) {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('orange.200', 'orange.700');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Container maxW="container.xl" py={8}>
      <Box
        bg={bgColor}
        borderRadius="xl"
        overflow="hidden"
        boxShadow="xl"
        border="1px"
        borderColor={borderColor}
        p={8}
      >
        <VStack spacing={6} align="center">
          <Icon as={Icon} w={16} h={16} color="orange.500" />
          <Heading as="h2" size="xl" textAlign="center" color="orange.500">
            {title}
          </Heading>
          <Text fontSize="lg" textAlign="center" color={textColor}>
            {message}
          </Text>
          {children}
          <Button
            colorScheme="orange"
            variant="outline"
            size="lg"
            _hover={{ bg: 'orange.50' }}
            onClick={onButtonClick}
            mt={4}
          >
            {buttonText}
          </Button>

        </VStack>
      </Box>
    </Container>
  );
}

interface UserInfoCardProps {
  userData: DiscordUserData | null;
  userName: string;
  userId: string;
  label: string;
}

function UserInfoCard({ userData, userName, userId, label }: UserInfoCardProps) {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const avatarUrl = userData?.avatar
    ? `https://cdn.discordapp.com/avatars/${userId}/${userData.avatar}.png`
    : `https://cdn.discordapp.com/embed/avatars/${parseInt(userId) % 5}.png`;

  return (
    <Box
      bg={bgColor}
      borderRadius="lg"
      p={4}
      boxShadow="md"
      border="1px"
      borderColor={borderColor}
    >
      <VStack align="start" spacing={2}>
        <Text fontSize="sm" fontWeight="medium" color="gray.500">
          {label}
        </Text>
        <HStack>
          <Avatar size="md" name={userName} src={avatarUrl}>
            <AvatarBadge boxSize="1.25em" bg="green.500" />
          </Avatar>
          <VStack align="start" spacing={0}>
            <Text fontWeight="bold">{userName}</Text>
            <Text fontSize="xs" color="gray.500">
              ID: {userId}
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
}

interface StatCardProps {
  icon: React.ElementType;
  label: string;
  value: string;
}

function StatCard({ icon: Icon, label, value }: StatCardProps) {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box
      px={4}
      py={3}
      bg={bgColor}
      borderRadius="lg"
      boxShadow="md"
      border="1px"
      borderColor={borderColor}
    >
      <VStack align="start" spacing={1}>
        <HStack>
          <Icon size={16} color="orange.500" />
          <Text fontSize="sm" fontWeight="medium" color="gray.500">{label}</Text>
        </HStack>
        <Text fontSize="md" fontWeight="bold">
          {value}
        </Text>
      </VStack>
    </Box>
  );
}