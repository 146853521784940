import React, { useState, useEffect, forwardRef } from 'react';
import {
  Box,
  Text,
  Spinner,
  VStack,
  HStack,
  Flex,
  Icon,
  Heading,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  Grid,
  GridItem,
  BoxProps,
} from '@chakra-ui/react';
import { motion, AnimatePresence, isValidMotionProp } from 'framer-motion';
import { FiPhone, FiCheckCircle, FiXCircle, FiClock, FiCalendar, FiRepeat, FiAlertCircle, FiPlus, FiShield, FiShieldOff } from 'react-icons/fi';
import { get } from '../../utils/apiUtils';

interface UserNumber {
  user_id: string;
  number: string;
  channel: string;
  intents: number;
  verified: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
}

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);


const UserNumbers: React.FC<{ userId: string | null }> = ({ userId }) => {
  const [numbers, setNumbers] = useState<UserNumber[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgGradient = useColorModeValue('linear(to-r, orange.400, yellow.400)', 'linear(to-r, orange.600, yellow.600)');
  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedTextColor = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    const fetchNumbers = async () => {
      if (!userId) {
        setIsLoading(false);
        setError('No se ha proporcionado un ID de usuario.');
        return;
      }
      try {
        setIsLoading(true);
        const response = await get<UserNumber[]>(`number/search?user_id=${userId}`);
        if (response.success && response.data) {
          setNumbers(Array.isArray(response.data) ? response.data : [response.data]);
        } else {
          throw new Error(response.error || 'No se encontraron números para este usuario.');
        }
      } catch (error) {
        console.error('Error fetching user numbers:', error);
        setError(error instanceof Error ? error.message : 'Error al cargar los números.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchNumbers();
  }, [userId]);


  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const NumberCard = ({ number }: { number: UserNumber }) => (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      bg={cardBg}
      boxShadow="lg"
      borderRadius="xl"
      overflow="hidden"
      borderWidth="1px"
      borderColor={borderColor}
      width="100%"
    >
      <Box bgGradient={bgGradient} p={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack>
            <Icon as={FiPhone} boxSize={6} color="white" />
            <Heading size="lg" color="white">{number.number}</Heading>
          </HStack>
          <Tooltip label={number.verified ? 'Verificado' : 'No verificado'}>
            <Box>
              <Icon 
                as={number.verified ? FiShield : FiShieldOff} 
                boxSize={6} 
                color={number.verified ? 'green.400' : 'red.400'}
              />
            </Box>
          </Tooltip>
        </Flex>
      </Box>
      <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }} gap={4} p={6}>
        <GridItem>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" fontSize="sm">Estado</Text>
            <HStack>
              <Icon 
                as={number.blocked ? FiXCircle : FiCheckCircle} 
                color={number.blocked ? 'red.500' : 'green.500'} 
              />
              <Text>{number.blocked ? 'Bloqueado' : 'Activo'}</Text>
            </HStack>
          </VStack>
        </GridItem>
        <GridItem>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" fontSize="sm">Intentos restantes</Text>
            <HStack>
              <Icon as={FiRepeat} color="orange.500" />
              <Text fontSize="lg" fontWeight="bold">{number.intents}</Text>
            </HStack>
          </VStack>
        </GridItem>
        <GridItem>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" fontSize="sm">Creado</Text>
            <HStack>
              <Icon as={FiCalendar} color="orange.500" />
              <Text fontSize="sm">{formatDate(number.createdAt)}</Text>
            </HStack>
          </VStack>
        </GridItem>
        <GridItem>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" fontSize="sm">Actualizado</Text>
            <HStack>
              <Icon as={FiClock} color="orange.500" />
              <Text fontSize="sm">{formatDate(number.updatedAt)}</Text>
            </HStack>
          </VStack>
        </GridItem>
      </Grid>
    </MotionBox>
  );

  const NoNumbersFound = () => (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      textAlign="center"
      py={10}
    >
      <Icon as={FiAlertCircle} boxSize={16} color="orange.500" mb={4} />
      <Heading as="h2" size="xl" mb={4} color={textColor}>
        No se encontraron números
      </Heading>
      <Text fontSize="lg" color={mutedTextColor} mb={6}>
        Parece que aún no tienes números vinculados a tu cuenta.
      </Text>
      <Button
        leftIcon={<FiPlus />}
        colorScheme="orange"
        size="lg"
        onClick={onOpen}
      >
        Agregar un número
      </Button>
    </MotionBox>
  );

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minHeight="50vh">
        <Spinner size="xl" color="orange.500" thickness="4px" />
      </Flex>
    );
  }

  return (
    <Box width="100%" maxWidth="100%" py={8} px={4}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="2xl" color="black.500" textAlign="center">
          Mi número
        </Heading>
        <Text fontSize="lg" color={mutedTextColor} textAlign="center">
          Gestiona tu número de teléfono vinculado
        </Text>
        <AnimatePresence>
          {error ? (
            <NoNumbersFound />
          ) : (
            <VStack spacing={6} align="stretch">
              {numbers.map((number) => (
                <NumberCard key={number.number} number={number} />
              ))}
            </VStack>
          )}
        </AnimatePresence>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar un nuevo número</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Funcionalidad en desarrollo...</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserNumbers;