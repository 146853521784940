import { useState, useEffect, useCallback } from "react";
import { get } from "./apiUtils"; 

const ADMIN_ROLES = ["596402057758375966", "597495855100067850"];
const STAFF_ROLES = ["597496371729268746"];

export const useAdminStatus = (discordId: string | null) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const checkStatus = useCallback(async () => {
        if (!discordId) {
            setIsAdmin(false);
            setIsStaff(false);
            setIsLoading(false);
            return;
        }

        try {
            const response = await get<{ roles: string[] }>(`user-roles/${discordId}`);
            
            if (response.success) {
                setIsAdmin(response.data?.roles.some((role: string) => ADMIN_ROLES.includes(role)) || false);
                setIsStaff(response.data?.roles.some((role: string) => STAFF_ROLES.includes(role)) || false);
            } else {
                setIsAdmin(false);
                setIsStaff(false);
            }
        } catch (error) {
            console.error("Error checking user status:", error);
            setIsAdmin(false);
            setIsStaff(false);
        } finally {
            setIsLoading(false);
        }
    }, [discordId]);

    useEffect(() => {
        if (discordId) {
            checkStatus();
        } else {
            setIsAdmin(false);
            setIsStaff(false);
            setIsLoading(false);
        }
    }, [discordId]);

    return { isAdmin, isStaff, isLoading };
};