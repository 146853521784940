import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import { AuthProvider } from './contexts/AuthContext';
import AuthCallback from './components/auth/AuthCallback';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import TicketLog from './components/log/TicketLog';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Leaderboard from './components/leaderboard/Leaderboard';
import LeaderboardStats from './components/leaderboard/mods/LeaderboardStats';

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <Router>
        <AuthProvider>
          <Flex direction="column" minHeight="100vh">
            <Header />
            <Box flex="1" as="main" bg="gray.50">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/admin" element={<Dashboard />} />
                <Route path="/auth/callback" element={<AuthCallback />} />
                <Route path="/tickets-logs/:fileName" element={<TicketLog />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route 
                  path="/leaderboard/:gameMode/:category" 
                  element={<LeaderboardStats />} 
                />
                <Route 
                  path="/leaderboard/:gameMode/:category/search/:username" 
                  element={<LeaderboardStats />} 
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
            <Footer />
          </Flex>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;