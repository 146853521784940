import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Icon,
  Spinner,
  useColorModeValue,
  Container,
  Badge,
  Tooltip,
  useToast,
  useBreakpointValue,
  Link,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Heading,
  Flex,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  BoxProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FiFileText, FiUser, FiCalendar, FiFilter, FiRefreshCw, FiChevronDown, FiExternalLink, FiMessageSquare, FiCopy, FiClock, FiHash } from 'react-icons/fi';
import { motion, AnimatePresence, isValidMotionProp } from 'framer-motion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale/es';
import { get } from '../../utils/apiUtils';

registerLocale('es', es);

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

interface Transcript {
  _id: string;
  user_id: string;
  user_name: string;
  closer_id: string;
  closer_name: string;
  channel_id: string;
  filename: string;
  reason: string;
  file_size: number;
  type: string;
  createdAt: string;
  updatedAt: string;
}

const CopyableText: React.FC<{ text: string; maxLength?: number }> = ({ text, maxLength = 10 }) => {
  const toast = useToast();
  const displayText = text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Texto copiado",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <Tooltip label="Haz clic para copiar" placement="top">
      <HStack spacing={1} cursor="pointer" onClick={handleCopy}>
        <Text isTruncated maxW="150px">{displayText}</Text>
        <Icon as={FiCopy} color="gray.500" w={3} h={3} />
      </HStack>
    </Tooltip>
  );
};

const UserTranscripts: React.FC<{ userId: string | null }> = ({ userId }) => {
  const [allTranscripts, setAllTranscripts] = useState<Transcript[]>([]);
  const [displayedTranscripts, setDisplayedTranscripts] = useState<Transcript[]>([]);
  const [filteredTranscripts, setFilteredTranscripts] = useState<Transcript[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedType, setSelectedType] = useState<string>('all');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const bgGradient = useColorModeValue('linear(to-r, orange.400, yellow.400)', 'linear(to-r, orange.600, yellow.600)');
  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedTextColor = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const accentColor = useColorModeValue('orange.500', 'orange.300');
  const tableHeaderBgColor = useColorModeValue('gray.50', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchTranscripts = async () => {
      if (!userId) return;
      try {
        setIsLoading(true);
        const response = await get<Transcript[]>(`transcripts/search?user_id=${userId}`);
        if (response.success && response.data) {
          setAllTranscripts(response.data);
          applyFilters(response.data);
        } else {
          throw new Error(response.error || 'Failed to fetch transcripts');
        }
      } catch (error) {
        console.error('Error fetching user transcripts:', error);
        setError('Error al cargar los transcripts. Por favor, intenta de nuevo más tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTranscripts();
  }, [userId]);

  const applyFilters = (transcripts: Transcript[]) => {
    let filtered = transcripts;

    if (startDate) {
      filtered = filtered.filter(t => new Date(t.createdAt) >= startDate);
    }
    if (endDate) {
      filtered = filtered.filter(t => new Date(t.createdAt) <= endDate);
    }
    if (selectedType !== 'all') {
      filtered = filtered.filter(t => t.type === selectedType);
    }

    setFilteredTranscripts(filtered);
    setDisplayedTranscripts(filtered.slice(0, itemsPerPage));
    setPage(1);
  };

  useEffect(() => {
    applyFilters(allTranscripts);
  }, [startDate, endDate, selectedType]);

  const hasMore = useMemo(() => page * itemsPerPage < filteredTranscripts.length, [page, filteredTranscripts]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setDisplayedTranscripts(filteredTranscripts.slice(0, nextPage * itemsPerPage));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const formatTime = (dateString: string) => {
    return new Date(dateString).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const uniqueTypes = useMemo(() => {
    const types = new Set(allTranscripts.map((t) => t.type));
    return ['all', ...Array.from(types).filter(Boolean)];
  }, [allTranscripts]);

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedType('all');
    applyFilters(allTranscripts);
  };

  const CustomInput = React.forwardRef<HTMLInputElement, { value?: string; onClick?: () => void; label: string }>(
    ({ value, onClick, label }, ref) => (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FiCalendar} color={accentColor} />
        </InputLeftElement>
        <Input
          ref={ref}
          value={value}
          onClick={onClick}
          readOnly
          placeholder={label}
          bg={cardBg}
          _hover={{ borderColor: accentColor }}
          _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
        />
      </InputGroup>
    )
  );

  const TranscriptCard = ({ transcript }: { transcript: Transcript }) => (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      bg={cardBg}
    >
      <Box bgGradient={bgGradient} p={4}>
        <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <HStack mb={2}>
            <Icon as={FiFileText} boxSize={6} color="white" />
            <Heading size="md" color="white">{transcript.filename}</Heading>
          </HStack>
          <Badge colorScheme="orange" fontSize="xs" px={2} py={1} borderRadius="full">
            {transcript.type || 'Sin categoría'}
          </Badge>
        </Flex>
      </Box>
      <Box p={4}>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
          <GridItem>
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Icon as={FiUser} color={accentColor} />
                <Text fontSize="sm" fontWeight="bold">Usuario:</Text>
                <CopyableText text={transcript.user_name} />
              </HStack>
              <HStack>
                <Icon as={FiHash} color={accentColor} />
                <Text fontSize="sm" fontWeight="bold">ID Usuario:</Text>
                <CopyableText text={transcript.user_id} />
              </HStack>
              <HStack>
                <Icon as={FiUser} color={accentColor} />
                <Text fontSize="sm" fontWeight="bold">Cerrado por:</Text>
                <CopyableText text={transcript.closer_name} />
              </HStack>
              <HStack>
                <Icon as={FiHash} color={accentColor} />
                <Text fontSize="sm" fontWeight="bold">ID Cerrador:</Text>
                <CopyableText text={transcript.closer_id} />
              </HStack>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Icon as={FiCalendar} color={accentColor} />
                <Text fontSize="sm" fontWeight="bold">Creado:</Text>
                <Text fontSize="sm">{formatDate(transcript.createdAt)}</Text>
              </HStack>
              <HStack>
                <Icon as={FiClock} color={accentColor} />
                <Text fontSize="sm" fontWeight="bold">Actualizado:</Text>
                <Text fontSize="sm">{formatDate(transcript.updatedAt)}</Text>
              </HStack>
              <HStack>
                <Icon as={FiMessageSquare} color={accentColor} />
                <Text fontSize="sm" fontWeight="bold">Razón:</Text>
                <CopyableText text={transcript.reason || 'N/A'} />
              </HStack>
              <Link
                href={`/tickets-logs/${transcript.filename}`}
                color={accentColor}
                fontWeight="medium"
                fontSize="sm"
                _hover={{ textDecoration: 'none', color: 'brand.600' }}
              >
                <HStack>
                  <Icon as={FiExternalLink} />
                  <Text>Ver transcript</Text>
                </HStack>
              </Link>
            </VStack>
          </GridItem>
        </Grid>
      </Box>
    </MotionBox>
  );

  const FilterContent = () => (
    <VStack spacing={4} align="stretch">
      <Box>
        <Text mb={2} fontWeight="bold">Fecha de inicio:</Text>
        <DatePicker
          selected={startDate}
          onChange={(date: Date | null) => setStartDate(date)}
          selectsStart
          startDate={startDate || undefined}
          endDate={endDate || undefined}
          locale="es"
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInput label="Desde" />}
        />
      </Box>
      <Box>
        <Text mb={2} fontWeight="bold">Fecha de fin:</Text>
        <DatePicker
          selected={endDate}
          onChange={(date: Date | null) => setEndDate(date)}
          selectsEnd
          startDate={startDate || undefined}
          endDate={endDate || undefined} 
          minDate={startDate || undefined} 
          locale="es"
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInput label="Hasta" />}
        />
      </Box>
      <Box>
        <Text mb={2} fontWeight="bold">Tipo de transcript:</Text>
        <Select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          bg={cardBg}
          borderColor={borderColor}
          _hover={{ borderColor: accentColor }}
          _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
        >
          {uniqueTypes.map((type) => (
            <option key={type} value={type}>
              {type === 'all' ? 'Todos los tipos' : type || 'Sin categoría'}
            </option>
          ))}
        </Select>
      </Box>
      <Button
        leftIcon={<FiRefreshCw />}
        onClick={clearFilters}
        colorScheme="orange"
        variant="outline"
      >
        Limpiar filtros
      </Button>
    </VStack>
  );

  const FilterDrawer = () => (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader bgGradient={bgGradient} color="white">Filtros</DrawerHeader>
        <DrawerBody>
          <FilterContent />
          <Button onClick={onClose} colorScheme="orange" width="100%" mt={4}>
            Aplicar filtros
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  return (
    <Box maxW="100%" overflowX="hidden">
      <Container maxW="container.lg" px={4} py={8}>
        <VStack spacing={8} align="stretch">
            <Heading as="h1" size="2xl" color="black"   textAlign="center" mb={2}>
              Mis Transcripts
            </Heading>
            <Text fontSize="lg" color={mutedTextColor} textAlign="center">
              Gestiona tus transcripts webs
            </Text>

          <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
              {filteredTranscripts.length} transcripts encontrados
            </Text>
            {isMobile ? (
              <Button
                leftIcon={<FiFilter />}
                onClick={onOpen}
                colorScheme="orange"
                variant="outline"
                size="sm"
              >
                Filtros
              </Button>
            ) : (
              <Popover>
                <PopoverTrigger>
                  <Button
                    leftIcon={<FiFilter />}
                    colorScheme="orange"
                    variant="outline"
                    size="sm"
                  >
                    Filtros
                  </Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <FilterContent />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Flex>

          {error && (
            <Box bg="red.100" color="red.700" p={4} borderRadius="md">
              <Text>{error}</Text>
            </Box>
          )}

          {isLoading ? (
            <Flex justify="center" align="center" minHeight="200px">
              <Spinner size="xl" color={accentColor} thickness="4px" />
            </Flex>
          ) : displayedTranscripts.length > 0 ? (
            <>
              <AnimatePresence>
                {isMobile ? (
                  <VStack spacing={4} align="stretch">
                    {displayedTranscripts.map((transcript) => (
                      <TranscriptCard key={transcript._id} transcript={transcript} />
                    ))}
                  </VStack>
                ) : (
                  <Box overflowX="auto">
                    <Table variant="simple">
                      <Thead bg={tableHeaderBgColor}>
                        <Tr>
                          <Th>Nombre del archivo</Th>
                          <Th>Usuario</Th>
                          <Th>Cerrado por</Th>
                          <Th>Fecha</Th>
                          <Th>Tipo</Th>
                          <Th>Razón</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {displayedTranscripts.map((transcript) => (
                          <Tr key={transcript._id} _hover={{ bg: hoverBgColor }}>
                            <Td>
                              <HStack>
                                <Icon as={FiFileText} color={accentColor} />
                                <CopyableText text={transcript.filename} />
                              </HStack>
                            </Td>
                            <Td>
                              <Tooltip label={`ID: ${transcript.user_id}`}>
                                <HStack>
                                  <Icon as={FiUser} color={accentColor} />
                                  <CopyableText text={transcript.user_name} />
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Tooltip label={`ID: ${transcript.closer_id}`}>
                                <HStack>
                                  <Icon as={FiUser} color={accentColor} />
                                  <CopyableText text={transcript.closer_name} />
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Tooltip label={formatTime(transcript.createdAt)}>
                                <HStack>
                                  <Icon as={FiCalendar} color={accentColor} />
                                  <Text>{formatDate(transcript.createdAt)}</Text>
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Badge colorScheme="orange">
                                {transcript.type || 'N/A'}
                              </Badge>
                            </Td>
                            <Td>
                              <Tooltip label={transcript.reason || 'N/A'}>
                                <HStack>
                                  <Icon as={FiMessageSquare} color={accentColor} />
                                  <CopyableText text={transcript.reason || 'N/A'} maxLength={10} />
                                </HStack>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Link
                                href={`/tickets-logs/${transcript.filename}`}
                                color={accentColor}
                                _hover={{ textDecoration: 'none' }}
                              >
                                <HStack>
                                  <Icon as={FiExternalLink} />
                                </HStack>
                              </Link>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </AnimatePresence>
              {hasMore && (
                <Button
                  onClick={loadMore}
                  colorScheme="orange"
                  size="lg"
                  width="100%"
                  leftIcon={<FiChevronDown />}
                  _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                  transition="all 0.2s"
                  borderRadius="full"
                  fontWeight="bold"
                  fontSize="md"
                  mt={6}
                >
                  Cargar más transcripts
                </Button>
              )}
            </>
          ) : (
            <Box textAlign="center" py={10}>
              <Icon as={FiFileText} boxSize={16} color={accentColor} mb={4} />
              <Heading as="h2" size="xl" mb={4} color={textColor}>
                No se encontraron transcripts
              </Heading>
              <Text fontSize="lg" color={mutedTextColor}>
                No hay transcripts disponibles con los filtros actuales.
              </Text>
            </Box>
          )}
        </VStack>
      </Container>
      <FilterDrawer />
    </Box>
  );
};

export default UserTranscripts;