import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Heading,
  Text,
  Container,
  Icon,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  Avatar,
  Badge,
  Tooltip,
  Spinner,
  Divider,
  Grid,
  GridItem,
  BoxProps,
} from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';
import { FiUser, FiFileText, FiPhone, FiSettings, FiList, FiMenu, FiUsers, FiUserX, FiLock, FiAlertTriangle, FiLogIn, FiShield, FiKey, FiClock, FiMail, FiHash, FiEyeOff, FiRefreshCw, FiActivity } from 'react-icons/fi';
import { useAuth } from '../contexts/AuthContext';
import { useAdminStatus } from '../utils/adminUtils';

import UserNumbers from '../components/user/UserNumbers';
import UserTranscripts from '../components/user/UserTranscripts';
import AdminNumbers from '../components/admin/AdminNumbers';
import AdminTranscripts from '../components/admin/AdminTranscripts';
import AdminStaff from '../components/admin/AdminStaff';
import AdminKicks from '../components/admin/AdminKicks';
import AdminRetires from '../components/admin/AdminRetires';
import AdminLinkedUsers from '../components/admin/AdminLinkedUsers';
import AdminTokenManagement from '../components/admin/AdminTokenManagement';
import ApiStatus from '../components/admin/AdminApiStatus';

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

const Dashboard: React.FC = () => {
  const { user, discordId, isAuthenticated, logout } = useAuth();
  const { isAdmin, isStaff, isLoading } = useAdminStatus(discordId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();




  const [isRefreshing, setIsRefreshing] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const avatarBg = useColorModeValue('orange.100', 'orange.700');
  const pageBgColor = useColorModeValue('gray.50', 'gray.900');
  const accentColor = useColorModeValue('orange.500', 'orange.300');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');

  const getActiveSection = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('category') || 'numbers';
  };




  const renderContent = () => {
    if (!isAuthenticated) {
      return <RestrictedAreaContent />;
    }

    if (isRefreshing) {
      return (
        <Flex justify="center" align="center" minHeight="50vh">
          <Spinner size="xl" color="orange.500" />
        </Flex>
      );
    }

    const activeSection = getActiveSection();
    switch (activeSection) {
      case 'numbers':
        return <UserNumbers userId={discordId} />;
      case 'transcripts':
        return <UserTranscripts userId={discordId} />;
      case 'adminNumbers':
        return isAdmin ? <AdminNumbers /> : <UnauthorizedContent />;
      case 'adminTranscripts':
        return isAdmin ? <AdminTranscripts /> : <UnauthorizedContent />;
      case 'adminStaff':
        return isAdmin ? <AdminStaff /> : <UnauthorizedContent />;
      // case 'adminKicks':
      //   return isAdmin ? <AdminKicks /> : <UnauthorizedContent />;
      case 'adminLinkedUsers':
        return isAdmin ? <AdminLinkedUsers /> : <UnauthorizedContent />;
      // case 'adminRetires':
      //   return isAdmin ? <AdminRetires /> : <UnauthorizedContent />;
      case 'tokenAdmin':
        return isAdmin ? <AdminTokenManagement /> : <UnauthorizedContent />;
      case 'apiStatus':
        return isAdmin ? <ApiStatus /> : <UnauthorizedContent />;
      default:
        return <UnauthorizedContent />;
    }
  };

  const NavButton: React.FC<{ icon: React.ElementType; section: string; children: React.ReactNode }> = ({ icon, section, children }) => (
    <Tooltip label={children} placement="right" hasArrow>
      <Button
        leftIcon={<Icon as={icon} boxSize={5} />}
        variant={getActiveSection() === section ? 'solid' : 'ghost'}
        colorScheme="orange"
        justifyContent="flex-start"
        width="full"
        fontWeight="medium"
        fontSize="sm"
        py={6}
        onClick={() => {
          const path = isAdmin && section.startsWith('admin') ? '/dashboard/admin' : '/dashboard';
          navigate(`${path}?category=${section}`);
          if (isMobile) onClose();
        }}
        _hover={{ bg: 'orange.100' }}
        transition="all 0.2s"
      >
        {children}
      </Button>
    </Tooltip>
  );

  const UserProfile = () => (
    <VStack spacing={4} align="center" p={6}>
      <Avatar
        size="xl"
        name={user?.user_metadata?.global_name || 'Usuario'}
        src={user?.user_metadata?.avatar_url}
        bg={avatarBg}
        border="4px solid"
        borderColor={accentColor}
      />
      <VStack spacing={1} align="center">
        <Heading as="h2" size="md" color={textColor} fontWeight="bold">
          {user?.user_metadata?.custom_claims?.global_name || 'Usuario'}
        </Heading>
        <Text fontSize="sm" color={secondaryTextColor}>
          <Icon as={FiMail} mr={1} aria-hidden="true" />{user?.email}
        </Text>
        <Text fontSize="sm" color={secondaryTextColor}>
          <Icon as={FiHash} mr={1} aria-hidden="true" />{user?.user_metadata?.sub}
        </Text>
        <Text fontSize="xs" color={secondaryTextColor}>
          <Icon as={FiClock} mr={1} aria-hidden="true" />Último acceso: {user?.last_sign_in_at ? new Date(user.last_sign_in_at).toLocaleString() : 'N/A'}
        </Text>
      </VStack>
      <Badge colorScheme={isAdmin ? 'orange' : isStaff ? 'blue' : 'green'} fontSize="0.8em" px={3} py={1} borderRadius="full">
        <HStack spacing={1}>
          <Icon as={isAdmin ? FiShield : isStaff ? FiUsers : FiUser} aria-hidden="true" />
          <Text>{isAdmin ? 'Administrador' : isStaff ? 'Staff' : 'Usuario'}</Text>
        </HStack>
      </Badge>

      
      {/* <Button
        leftIcon={<FiRefreshCw />}
        colorScheme="orange"
        variant="ghost"
        size="sm"
        onClick={handleManualRefresh}
        isLoading={isRefreshing}
        loadingText="Actualizando..."
      >
        Actualizar permisos
      </Button> */}
    </VStack>
  );

  const Navigation = () => {
    const navItems = [
      { icon: FiPhone, section: 'numbers', label: 'Mis Números' },
      { icon: FiFileText, section: 'transcripts', label: 'Mis Transcripts' },
    ];

    const adminNavItems = [
      { icon: FiSettings, section: 'adminNumbers', label: 'Todos los Números' },
      { icon: FiList, section: 'adminTranscripts', label: 'Todos los Transcripts' },
      { icon: FiUsers, section: 'adminStaff', label: 'Personal' },
      // { icon: FiUserX, section: 'adminKicks', label: 'Kicks' },
      // { icon: FiUserX, section: 'adminRetires', label: 'Retires' },
      { icon: FiUserX, section: 'adminLinkedUsers', label: 'Usuarios Vinculados' },
      { icon: FiKey, section: 'tokenAdmin', label: 'Gestión de Tokens' },
      { icon: FiActivity, section: 'apiStatus', label: 'Estado del API' },
    ];

    return (
      <VStack spacing={1} align="stretch" pl={2}>
        <Heading as="h3" size="xs" textTransform="uppercase" letterSpacing="wide" mb={2} color={accentColor}>
          Navegación
        </Heading>
        {navItems.map((item) => (
          <NavButton key={item.section} icon={item.icon} section={item.section}>
            {item.label}
          </NavButton>
        ))}
        {isAdmin && (
          <>
            <Divider my={4} />
            <Heading as="h3" size="xs" textTransform="uppercase" letterSpacing="wide" mb={2} color={accentColor}>
              Administración
            </Heading>
            {adminNavItems.map((item) => (
              <NavButton key={item.section} icon={item.icon} section={item.section}>
                {item.label}
              </NavButton>
            ))}
          </>
        )}
      </VStack>
    );
  };

  const SidebarContent = () => (
    <VStack spacing={6} align="stretch">
      <MotionBox
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        bg={bgColor}
        borderRadius="2xl"
        overflow="hidden"
        boxShadow="lg"
        borderWidth="1px"
        borderColor={borderColor}
      >
        <Box
          height="80px"
          bgGradient="linear(to-r, orange.400, yellow.400)"
          position="relative"
          overflow="hidden"
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bgImage="url('data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.2' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' 
            r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E')"
          />
        </Box>
        <UserProfile />
      </MotionBox>
      <Navigation />
      {isAuthenticated && (
        <Button
          leftIcon={<FiLogIn />}
          colorScheme="red"
          variant="outline"
          onClick={logout}
          mt={4}
          w="full"
        >
          Cerrar sesión
        </Button>
      )}
    </VStack>
  );

  if (isLoading) {
    return (
      <Container maxW="container.xl" py={8}>
        <Flex justify="center" align="center" minHeight="50vh">
          <Spinner size="xl" color="orange.500" />
        </Flex>
      </Container>
    );
  }

  return (
    <Box minHeight="100vh" bg={pageBgColor}>
      <Container maxW="container.xl" py={8}>
        {isMobile && (
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Heading as="h1" size="lg">Dashboard</Heading>
            <IconButton
              icon={<FiMenu />}
              onClick={onOpen}
              variant="outline"
              aria-label="Abrir menú"
              colorScheme="orange"
            />
          </Flex>
        )}
        <Grid templateColumns={{ base: '1fr', md: '240px 1fr', lg: '280px 1fr' }} gap={8}>
          {!isMobile && (
            <GridItem>
              <Box position="sticky" top="20px">
                <SidebarContent />
              </Box>
            </GridItem>
          )}
          <GridItem>
            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              bg={bgColor}
              borderRadius="lg"
              boxShadow="md"
              p={6}
              borderWidth="1px"
              borderColor={borderColor}
            >
              {renderContent()}
            </MotionBox>
          </GridItem>
        </Grid>
      </Container>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menú</DrawerHeader>
            <DrawerBody>
              <SidebarContent />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

const RestrictedAreaContent: React.FC = () => {
  const bgColor = useColorModeValue('orange.50', 'orange.900');
  const borderColor = useColorModeValue('orange.200', 'orange.700');
  const textColor = useColorModeValue('orange.800', 'orange.100');
  const { login } = useAuth();

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      p={8}
      textAlign="center"
    >
      <VStack spacing={8}>
        <MotionBox
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 10, -10, 0],
          }}
          transition={{
            duration: 3,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1
          }}
        >
          <Icon as={FiEyeOff} w={24} h={24} color="orange.500" />
        </MotionBox>
        <VStack spacing={3}>
          <Heading as="h2" size="2xl" color={textColor}>
            Área Restringida
          </Heading>
          <Text fontSize="xl" color={textColor}>
            Esta sección es solo para usuarios autorizados.
          </Text>
        </VStack>
        <Button
          onClick={login}
          size="lg"
          colorScheme="orange"
          leftIcon={<FiLogIn />}
          _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
          transition="all 0.2s"
        >
          Iniciar sesión
        </Button>
      </VStack>
    </MotionBox>
  );
};

const UnauthorizedContent: React.FC = () => {
  const bgColor = useColorModeValue('red.50', 'red.900');
  const borderColor = useColorModeValue('red.200', 'red.700');
  const textColor = useColorModeValue('red.800', 'red.100');

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      p={8}
      textAlign="center"
    >
      <VStack spacing={8}>
        <MotionBox
          animate={{ scale: [0.95, 1, 0.95] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        >
          <Icon as={FiAlertTriangle} w={24} h={24} color="red.500" />
        </MotionBox>
        <VStack spacing={3}>
          <Heading as="h2" size="2xl" color={textColor}>
            Acceso Denegado
          </Heading>
          <Text fontSize="xl" color={textColor}>
            No tienes los permisos necesarios para ver esta sección.
          </Text>
        </VStack>
        <Button
          as="a"
          href="/dashboard"
          size="lg"
          colorScheme="red"
          variant="outline"
          leftIcon={<FiRefreshCw />}
          _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
          transition="all 0.2s"
        >
          Volver al Dashboard
        </Button>
      </VStack>
    </MotionBox>
  );
};



export default Dashboard;

