import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { motion, AnimatePresence } from "framer-motion"
import {
	Box,
	Button,
	Container,
	Heading,
	Text,
	SimpleGrid,
	VStack,
	HStack,
	Icon,
	Link,
	useColorModeValue,
	Flex,
	Avatar,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	useBreakpointValue,
	chakra,
	Circle,
	Tooltip,
} from "@chakra-ui/react"
import {
	FiSearch,
	FiServer,
	FiLogIn,
	FiStar,
	FiUser,
	FiMessageCircle,
	FiTag,
	FiCheckCircle,
	FiAlertCircle,
	FiArrowRight,
	FiUsers,
	FiMessageSquare,
} from "react-icons/fi"
import { FaTicketAlt, FaDiscord } from "react-icons/fa"
import { get } from "../utils/apiUtils"

interface Review {
	id: number
	owner_id: string
	review_message: string
	review_points: number
	category: string
}

interface StarStat {
	review_points: number
	count: number
}

interface User {
	global_name: string
	avatar: string
	username: string
}

const MotionBox = chakra(motion.div)

export default function Home() {
	const { isAuthenticated, login } = useAuth()
	const [reviews, setReviews] = useState<Review[]>([])
	const [starStats, setStarStats] = useState<StarStat[]>([])
	const [currentReviewIndex, setCurrentReviewIndex] = useState(0)
	const [userDetails, setUsers] = useState<{ [key: string]: User }>({})

	useEffect(() => {
		const fetchData = async () => {
			try {
				const reviewsResponse = await get<Review[]>("reviews/random")
				if (reviewsResponse.success) {
					setReviews(
						reviewsResponse.data?.filter((review) => review.review_points > 0) || [],
					)
				}

				const statsResponse = await get<StarStat[]>("reviews/stars")
				if (statsResponse.success) {
					setStarStats(
						statsResponse.data?.filter(
							(stat) => stat.review_points > 0 && stat.review_points <= 5,
						) || [],
					)
				}

				const userPromises =
					reviewsResponse.data?.map(async (review) => {
						const userResponse = await get<User>(
							`discord-user-data/${review.owner_id}`,
						)
						return { owner_id: review.owner_id, user: userResponse.data || null }
					}) || []

				const usersData = await Promise.all(userPromises)
				const usersMap = usersData.reduce((acc, { owner_id, user }) => {
					if (user) {
						acc[owner_id] = user
					}
					return acc
				}, {} as { [key: string]: User })

				setUsers(usersMap)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length)
		}, 5000)

		return () => clearInterval(timer)
	}, [reviews.length])

	const features = [
		{
			icon: FaTicketAlt,
			title: "Crear Ticket",
			description: "Inicia una nueva solicitud de soporte",
			link: "/create-ticket",
		},
		{
			icon: FiSearch,
			title: "Buscar Tickets",
			description: "Encuentra y revisa tus tickets existentes",
			link: "/search-tickets",
		},
		{
			icon: FiServer,
			title: "Estado del Servidor",
			description: "Verifica el estado actual de nuestros servidores",
			link: "/server-status",
		},
	]

	const cardBorderColor = useColorModeValue("gray.200", "gray.700")
	const iconColor = useColorModeValue("orange.400", "orange.300")
	const linkColor = useColorModeValue("orange.500", "orange.300")
	const bgColor = useColorModeValue("gray.50", "gray.900")
	const cardBgColor = useColorModeValue("white", "gray.800")
	const headingColor = useColorModeValue("gray.800", "white")
	const textColor = useColorModeValue("gray.600", "gray.300")
	const accentColor = useColorModeValue("orange.500", "orange.300")
	const areaBoxBgColor = useColorModeValue("gray.100", "gray.700")

	const totalReviews = starStats.reduce((acc, stat) => acc + stat.count, 0)

	const staffRequirements = [
		"Poseer más de 15 años para Builder, Staff y Developer. 13 años o más para Marketing.",
		"Poseer buena ortografía.",
		"Buen manejo de Discord.",
		"30 días o más de antigüedad en la network.",
		"Poseer una actividad de 16 horas o más semanal.",
		"No tener ninguna sanción reciente (10 días mínimo).",
		"No tener multicuentas con sanciones activas.",
		"Ser un jugador respetuoso, modelo y siempre dispuesto a ayudar.",
		"Saber trabajar en equipo.",
	]

	const staffAreas = [
		{
			title: "Staff",
			description: "Modera y ayuda a los jugadores en el servidor.",
		},
		{
			title: "Builders",
			description: "Construye para el servidor y gana puntos canjeables.",
		},
		{
			title: "Marketing",
			description: "Haz publicidad para el servidor en redes sociales.",
		},
		{
			title: "Developers",
			description: "Desarrolla plugins y funcionalidades para el servidor.",
		},
	]

	const faqItems = [
		{
			question: "¿Puedo ser staff si no tengo 15 años?",
			answer: "Sí, pero solo en el equipo de Marketing y con buen rendimiento.",
		},
		{
			question: "¿Qué pasa si no cumplo con alguno de los requisitos?",
			answer: "Tendrás que esforzarte en cumplir con ellos antes de postularte.",
		},
		{
			question: "¿Gano algo por ser staff?",
			answer:
				"Obtienes beneficios como fly, kits especiales y puntos canjeables según tu actividad.",
		},
		{
			question: "¿En qué rango empiezo al ser staff?",
			answer:
				"Siempre se empieza como HELPER de modalidad, con posibilidad de ascenso.",
		},
	]

	const ticketSteps = [
		"Ingresa a nuestro servidor de Discord.",
		"Localiza la sala #soporte.",
		"Selecciona tu idioma y la categoría apropiada.",
		"Sigue las instrucciones del bot y explica tu problema.",
		"Espera la respuesta de nuestro equipo de soporte.",
	]

	const ticketRecommendations = [
		"Sé paciente, nuestro equipo responderá lo antes posible.",
		"Proporciona información detallada sobre tu problema.",
		"Evita usar lenguaje ofensivo o inapropiado.",
		"Mantén un tono respetuoso con el personal de soporte.",
	]

	const isMobile = useBreakpointValue({ base: true, md: false })

	return (
		<Box bg={bgColor} minH="100vh">
			<Container maxW="container.xl" py={10}>
				<VStack spacing={16} as="section">
					{/* Hero Section */}
					<MotionBox
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 as any }}
						w="full">
						<VStack spacing={6} textAlign="center">
							<Heading
								as="h1"
								size="2xl"
								fontWeight="bold"
								color={headingColor}
								lineHeight="1.2">
								Sistema de Tickets MineLatino
							</Heading>
							<Text fontSize="lg" color={textColor} maxW="2xl">
								Gestiona tus solicitudes de soporte de manera rápida y eficiente en
								nuestra plataforma de Minecraft
							</Text>
							{!isAuthenticated && (
								<Button
									onClick={login}
									size="lg"
									colorScheme="orange"
									leftIcon={<FiLogIn />}
									_hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
									transition="all 0.3s">
									Iniciar sesión con Discord
								</Button>
							)}
						</VStack>
					</MotionBox>
					{/* Features Section */}
					<MotionBox
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8, delay: 0.2 } as any}
						w="full">
						<SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
							{features.map((feature, index) => (
								<MotionBox
									key={index}
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.5, delay: 0.1 * index } as any}>
									<VStack
										bg={cardBgColor}
										p={6}
										rounded="xl"
										shadow="xl"
										borderWidth="1px"
										borderColor={cardBorderColor}
										_hover={{ transform: "translateY(-5px)", shadow: "2xl" }}
										transition="all 0.3s"
										height="full"
										spacing={4}>
										<Icon as={feature.icon} w={8} h={8} color={iconColor} />
										<Heading as="h3" size="md" color={headingColor}>
											{feature.title}
										</Heading>
										<Text color={textColor} textAlign="center" fontSize="sm">
											{feature.description}
										</Text>
										<Box flex={1} />
										<Link
											as={RouterLink}
											to={feature.link}
											color={linkColor}
											fontWeight="bold"
											fontSize="sm"
											_hover={{ textDecoration: "none" }}>
											<HStack>
												<Text>Ir ahora</Text>
												<Icon as={FiArrowRight} />
											</HStack>
										</Link>
									</VStack>
								</MotionBox>
							))}
						</SimpleGrid>
					</MotionBox>

					{/* Combined Reviews and Statistics Section */}
					<MotionBox
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8, delay: 0.4 } as any}
						w="full">
						<Box bg={cardBgColor} p={8} rounded="2xl" shadow="xl" overflow="hidden">
							<Heading
								as="h2"
								size="xl"
								mb={8}
								color={headingColor}
								textAlign="center">
								La Voz de Nuestra Comunidad
							</Heading>

							<SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} alignItems="center">
								{/* Reviews Carousel */}
								<Box position="relative" height="300px">
									<AnimatePresence mode="wait">
										<MotionBox
											key={currentReviewIndex}
											initial={{ opacity: 0, x: 50 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: -50 }}
											transition={{ duration: 0.5 } as any}
											position="absolute"
											top="0"
											left="0"
											right="0"
											bottom="0"
											display="flex"
											alignItems="center"
											justifyContent="center">
											{reviews[currentReviewIndex] && (
												<VStack spacing={4} align="center" maxW="400px">
													<Avatar
														size="lg"
														name={
															userDetails[reviews[currentReviewIndex].owner_id]?.global_name ||
															"Usuario"
														}
														src={`https://cdn.discordapp.com/avatars/${
															reviews[currentReviewIndex].owner_id
														}/${
															userDetails[reviews[currentReviewIndex].owner_id]?.avatar
														}.png`}
													/>
													<Text fontSize="lg" fontWeight="bold" color={headingColor}>
														{userDetails[reviews[currentReviewIndex].owner_id]?.username ||
															"Usuario"}
													</Text>
													<HStack spacing={1}>
														{[...Array(5)].map((_, i) => (
															<Icon
																key={i}
																as={FiStar}
																color={
																	i < reviews[currentReviewIndex].review_points
																		? "orange.400"
																		: "gray.300"
																}
																w={5}
																h={5}
															/>
														))}
													</HStack>
													<Text
														fontSize="md"
														color={textColor}
														textAlign="center"
														fontStyle="italic">
														"{reviews[currentReviewIndex].review_message}"
													</Text>
													<Text fontSize="sm" color={iconColor} fontWeight="semibold">
														{reviews[currentReviewIndex].category}
													</Text>
												</VStack>
											)}
										</MotionBox>
									</AnimatePresence>
								</Box>

								{/* Statistics */}
								<VStack spacing={6} align="stretch">
									<SimpleGrid columns={2} spacing={6}>
										{[
											{ icon: FiUser, label: "Total de Reseñas", value: totalReviews },
											{
												icon: FiStar,
												label: "Calificación Promedio",
												value: (
													starStats.reduce(
														(acc, stat) => acc + stat.review_points * stat.count,
														0,
													) / totalReviews
												).toFixed(1),
											},
											{
												icon: FiMessageCircle,
												label: "Reseñas 5 Estrellas",
												value:
													starStats.find((stat) => stat.review_points === 5)?.count || 0,
											},
											{
												icon: FiTag,
												label: "Categorías",
												value: new Set(reviews.map((review) => review.category)).size,
											},
										].map((stat, index) => (
											<MotionBox
												key={index}
												initial={{ opacity: 0, y: 20 }}
												animate={{ opacity: 1, y: 0 }}
												transition={{ duration: 0.5, delay: index * 0.1 } as any}>
												<Tooltip label={stat.label} placement="top">
													<VStack>
														<Circle
															size="60px"
															bg={`orange.${100 + index * 100}`}
															color={`orange.${600 + index * 100}`}>
															<Icon as={stat.icon} w={6} h={6} />
														</Circle>
														<Text fontSize="2xl" fontWeight="bold" color={headingColor}>
															{stat.value}
														</Text>
														<Text fontSize="sm" color={textColor} textAlign="center">
															{stat.label}
														</Text>
													</VStack>
												</Tooltip>
											</MotionBox>
										))}
									</SimpleGrid>

									{/* Star Distribution */}
									<Box mt={6}>
										<Text fontSize="sm" fontWeight="semibold" mb={2} color={headingColor}>
											Distribución de Estrellas
										</Text>
										{starStats.map((stat) => (
											<Flex key={stat.review_points} align="center" mb={2}>
												<Text
													fontSize="sm"
													fontWeight="medium"
													color={textColor}
													width="20px">
													{stat.review_points}
												</Text>
												<Icon as={FiStar} color="orange.400" w={4} h={4} mx={2} />
												<Box
													flex={1}
													bg="gray.200"
													h="8px"
													borderRadius="full"
													overflow="hidden">
													<Box
														as={motion.div}
														initial={{ width: 0 }}
														animate={{ width: `${(stat.count / totalReviews) * 100}%` }}
														transition={{ duration: 1, delay: 0.5 } as any}
														height="100%"
														bg="orange.400"
													/>
												</Box>
												<Text
													fontSize="sm"
													fontWeight="medium"
													color={textColor}
													ml={2}
													width="40px"
													textAlign="right">
													{((stat.count / totalReviews) * 100).toFixed(1)}%
												</Text>
											</Flex>
										))}
									</Box>
								</VStack>
							</SimpleGrid>
						</Box>
					</MotionBox>

					{/* Staff Application Section */}
					<MotionBox
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 } as any}
						w="full">
						<VStack spacing={8} align="stretch">
							<VStack spacing={4}>
								<Icon as={FiUsers} w={12} h={12} color={accentColor} />
								<Heading as="h2" size="2xl" color={headingColor} textAlign="center">
									Únete a Nuestro Equipo
								</Heading>
								<Text
									fontSize="lg"
									color={textColor}
									textAlign="center"
									maxW="2xl"
									mx="auto">
									¿Sabías que nuestras postulaciones para ser staff siempre están
									abiertas? ¡Forma parte del equipo de MineLatino Network!
								</Text>
							</VStack>

							<SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
								<MotionBox
									bg={cardBgColor}
									p={6}
									rounded="xl"
									shadow="xl"
									initial={{ opacity: 0, x: -50 }}
									animate={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5, delay: 0.2 } as any}>
									<VStack align="stretch" spacing={4}>
										<Heading as="h3" size="lg" color={headingColor}>
											Requisitos
										</Heading>
										{staffRequirements.map((req, index) => (
											<HStack key={index} spacing={3} align="start">
												<Icon as={FiCheckCircle} color="green.500" mt={1} />
												<Text fontSize="sm" color={textColor}>
													{req}
												</Text>
											</HStack>
										))}
									</VStack>
								</MotionBox>

								<MotionBox
									bg={cardBgColor}
									p={6}
									rounded="xl"
									shadow="xl"
									initial={{ opacity: 0, x: 50 }}
									animate={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5, delay: 0.4 } as any}>
									<VStack align="stretch" spacing={4}>
										<Heading as="h3" size="lg" color={headingColor}>
											Áreas Disponibles
										</Heading>
										<SimpleGrid columns={2} spacing={4}>
											{staffAreas.map((area, index) => (
												<Box key={index} p={4} bg={areaBoxBgColor} rounded="lg">
													<Text fontWeight="bold" fontSize="md" mb={2}>
														{area.title}
													</Text>

													<Text fontSize="sm" color={textColor}>
														{area.description}
													</Text>
												</Box>
											))}
										</SimpleGrid>
									</VStack>
								</MotionBox>
							</SimpleGrid>

							<MotionBox
								bg={cardBgColor}
								p={6}
								rounded="xl"
								shadow="xl"
								initial={{ opacity: 0, y: 50 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5, delay: 0.6 } as any}>
								<Heading as="h3" size="lg" color={headingColor} mb={4}>
									Preguntas Frecuentes
								</Heading>
								<Accordion allowMultiple>
									{faqItems.map((item, index) => (
										<AccordionItem key={index} border="none">
											<AccordionButton py={4} px={0} _hover={{ bg: "transparent" }}>
												<Box
													flex="1"
													textAlign="left"
													fontWeight="semibold"
													color={headingColor}>
													{item.question}
												</Box>
												<AccordionIcon color={accentColor} />
											</AccordionButton>
											<AccordionPanel pb={4} px={0}>
												<Text color={textColor}>{item.answer}</Text>
											</AccordionPanel>
										</AccordionItem>
									))}
								</Accordion>
							</MotionBox>

							<Button
								as="a"
								href="https://discord.gg/minelatino"
								size="lg"
								colorScheme="orange"
								leftIcon={<FiUsers />}
								variant="outline"
								_hover={{ bg: "orange.50" }}
								transition="all 0.3s">
								Postúlate Ahora
							</Button>
						</VStack>
					</MotionBox>

					{/* How to Open a Ticket Section */}
					<MotionBox
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8, delay: 0.2 } as any}
						w="full">
						<VStack spacing={8} align="stretch">
							<VStack spacing={4}>
								<Icon as={FiMessageSquare} w={12} h={12} color={accentColor} />
								<Heading as="h2" size="2xl" color={headingColor} textAlign="center">
									Cómo Abrir un Ticket
								</Heading>
								<Text
									fontSize="lg"
									color={textColor}
									textAlign="center"
									maxW="2xl"
									mx="auto">
									Si tienes algún problema, no dudes en abrir un ticket en nuestro
									servidor de Discord.
								</Text>
							</VStack>

							<SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
								<MotionBox
									bg={cardBgColor}
									p={6}
									rounded="xl"
									shadow="xl"
									initial={{ opacity: 0, x: -50 }}
									animate={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5, delay: 0.4 } as any}>
									<VStack align="stretch" spacing={4}>
										<Heading as="h3" size="lg" color={headingColor}>
											Pasos para Abrir un Ticket
										</Heading>
										{ticketSteps.map((step, index) => (
											<HStack key={index} spacing={3} align="start">
												<Box
													minW="24px"
													h="24px"
													rounded="full"
													bg={accentColor}
													color="white"
													fontSize="sm"
													fontWeight="bold"
													display="flex"
													alignItems="center"
													justifyContent="center">
													{index + 1}
												</Box>
												<Text fontSize="sm" color={textColor}>
													{step}
												</Text>
											</HStack>
										))}
									</VStack>
								</MotionBox>

								<MotionBox
									bg={cardBgColor}
									p={6}
									rounded="xl"
									shadow="xl"
									initial={{ opacity: 0, x: 50 }}
									animate={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5, delay: 0.6 } as any}>
									<VStack align="stretch" spacing={4}>
										<Heading as="h3" size="lg" color={headingColor}>
											Recomendaciones
										</Heading>
										{ticketRecommendations.map((rec, index) => (
											<HStack key={index} spacing={3} align="start">
												<Icon as={FiAlertCircle} color={accentColor} mt={1} />
												<Text fontSize="sm" color={textColor}>
													{rec}
												</Text>
											</HStack>
										))}
									</VStack>
								</MotionBox>
							</SimpleGrid>

							<Button
								as="a"
								href="https://discord.gg/minelatino"
								target="_blank"
								rel="noopener noreferrer"
								size="lg"
								colorScheme="orange"
								leftIcon={<FaDiscord />}
								variant="outline"
								_hover={{ bg: "orange.50" }}
								transition="all 0.3s">
								Unirse al Discord
							</Button>
						</VStack>
					</MotionBox>
				</VStack>
			</Container>
		</Box>
	)
}
