import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Badge,
  VStack,
  HStack,
  Icon,
  useColorModeValue,
  Spinner,
  Flex,
  Progress,
  Tooltip,
} from '@chakra-ui/react';
import {
  FiServer,
  FiCpu,
  FiHardDrive,
  FiClock,
  FiActivity,
  FiThermometer,
  FiZap,
  FiGlobe,
  FiPackage,
  FiDatabase,
} from 'react-icons/fi';
import { get } from '../../utils/apiUtils';

interface ApiStatusData {
  status: string;
  message: string;
  timestamp: string;
  uptime: number;
  nodeVersion: string;
  environment: string;
  connected: Record<string, string>;
  disconnected: Record<string, string>;
  memoryUsage: {
    rss: number;
    heapTotal: number;
    heapUsed: number;
    external: number;
  };
  systemInfo: {
    platform: string;
    arch: string;
    hostname: string;
    totalMemory: number;
    freeMemory: number;
    loadAverage: number[];
    cpuInfo: Array<{
      model: string;
      speed: number;
      times: {
        user: number;
        nice: number;
        sys: number;
        idle: number;
        irq: number;
      };
    }>;
    uptimeSystem: number;
  };
}

const ApiStatus: React.FC = () => {
  const [apiData, setApiData] = useState<ApiStatusData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');
  const cardBgColor = useColorModeValue('gray.50', 'gray.700');

  useEffect(() => {
    const fetchApiStatus = async () => {
      setIsLoading(true);
      try {
        const response = await get<ApiStatusData>('uptime');
        if (!response.success) {
          throw new Error(response.error || 'Failed to fetch API status');
        }
        if (response.data) {
          setApiData(response.data);
        } else {
          throw new Error('No data received');
        }
      } catch (err) {
        console.error('Error fetching API status:', err);
        setError('Error al cargar el estado de la API. Por favor, intenta de nuevo más tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchApiStatus();
    const interval = setInterval(fetchApiStatus, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, []);

  const formatBytes = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + ' ' + sizes[i];
  };

  const formatUptime = (seconds: number) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${days}d ${hours}h ${minutes}m`;
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minHeight="50vh">
        <Spinner size="xl" color="orange.500" />
      </Flex>
    );
  }

  if (error || !apiData) {
    return <Text color="red.500">{error || 'Error loading API status'}</Text>;
  }

  const cpuUsage = apiData.systemInfo.cpuInfo[0].times;
  const totalCpuTime = Object.values(cpuUsage).reduce((a, b) => a + b, 0);
  const cpuUsagePercentage = ((totalCpuTime - cpuUsage.idle) / totalCpuTime) * 100;

  return (
    <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md" borderWidth="1px" borderColor={borderColor}>
      <VStack spacing={8} align="stretch">
        <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Heading size="lg">Estado del Sistema</Heading>
          <Badge colorScheme={apiData.status === 'OK' ? 'green' : 'red'} fontSize="md" p={2} borderRadius="md">
            {apiData.status}
          </Badge>
        </Flex>
        
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
          <StatCard
            icon={FiActivity}
            label="Uptime API"
            value={formatUptime(apiData.uptime)}
            helpText="Desde el último reinicio"
          />
          <StatCard
            icon={FiClock}
            label="Uptime Sistema"
            value={formatUptime(apiData.systemInfo.uptimeSystem)}
            helpText="Tiempo total de actividad"
          />
          <StatCard
            icon={FiPackage}
            label="Versión Node"
            value={apiData.nodeVersion}
            helpText={`Entorno: ${apiData.environment}`}
          />
          <StatCard
            icon={FiGlobe}
            label="Plataforma"
            value={`${apiData.systemInfo.platform} (${apiData.systemInfo.arch})`}
            helpText={apiData.systemInfo.hostname}
          />
        </SimpleGrid>

        <Box>
          <Heading size="md" mb={4}>Recursos del Sistema</Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <ResourceCard
              icon={FiCpu}
              label="CPU"
              value={`${cpuUsagePercentage.toFixed(2)}%`}
              helpText={apiData.systemInfo.cpuInfo[0].model}
              progress={cpuUsagePercentage}
            />
            <ResourceCard
              icon={FiHardDrive}
              label="Memoria"
              value={`${formatBytes(apiData.systemInfo.totalMemory - apiData.systemInfo.freeMemory)} / ${formatBytes(apiData.systemInfo.totalMemory)}`}
              helpText="Memoria utilizada / total"
              progress={(apiData.systemInfo.totalMemory - apiData.systemInfo.freeMemory) / apiData.systemInfo.totalMemory * 100}
            />
          </SimpleGrid>
        </Box>

        <Box>
          <Heading size="md" mb={4}>Servicios Conectados</Heading>
          <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4}>
            {Object.entries({ ...apiData.connected, ...apiData.disconnected }).map(([service, status]) => (
              <ServiceStatusCard key={service} service={service} status={status} />
            ))}
          </SimpleGrid>
        </Box>

        <Box>
          <Heading size="md" mb={4}>Uso de Memoria</Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
            {Object.entries(apiData.memoryUsage).map(([key, value]) => (
              <MemoryUsageCard key={key} label={key} value={formatBytes(value)} />
            ))}
          </SimpleGrid>
        </Box>
      </VStack>
    </Box>
  );
};

const StatCard: React.FC<{ icon: React.ElementType; label: string; value: string; helpText: string }> = ({ icon, label, value, helpText }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  return (
    <Stat bg={cardBg} p={4} borderRadius="lg" boxShadow="sm">
      <Flex alignItems="center" mb={2}>
        <Icon as={icon} boxSize={6} color="orange.500" mr={2} />
        <StatLabel fontSize="sm" fontWeight="medium">{label}</StatLabel>
      </Flex>
      <StatNumber fontSize="2xl" fontWeight="bold">{value}</StatNumber>
      <StatHelpText fontSize="xs">{helpText}</StatHelpText>
    </Stat>
  );
};

const ResourceCard: React.FC<{ icon: React.ElementType; label: string; value: string; helpText: string; progress: number }> = ({ icon, label, value, helpText, progress }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  return (
    <Box bg={cardBg} p={4} borderRadius="lg" boxShadow="sm">
      <Flex alignItems="center" mb={2}>
        <Icon as={icon} boxSize={6} color="orange.500" mr={2} />
        <Text fontWeight="medium">{label}</Text>
      </Flex>
      <Text fontSize="2xl" fontWeight="bold" mb={2}>{value}</Text>
      <Progress value={progress} colorScheme="orange" size="sm" borderRadius="full" />
      <Text fontSize="xs" mt={2}>{helpText}</Text>
    </Box>
  );
};

const ServiceStatusCard: React.FC<{ service: string; status: string }> = ({ service, status }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  const isConnected = status === 'Conectado';
  return (
    <Tooltip label={status} placement="top">
      <Flex
        bg={cardBg}
        p={3}
        borderRadius="md"
        alignItems="center"
        justifyContent="space-between"
        borderWidth={1}
        borderColor={isConnected ? 'green.400' : 'red.400'}
      >
        <Text fontSize="sm" fontWeight="medium" isTruncated>{service}</Text>
        <Icon
          as={isConnected ? FiZap : FiThermometer}
          color={isConnected ? 'green.400' : 'red.400'}
          boxSize={5}
        />
      </Flex>
    </Tooltip>
  );
};

const MemoryUsageCard: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  return (
    <Box bg={cardBg} p={4} borderRadius="lg" boxShadow="sm">
      <Flex alignItems="center" mb={2}>
        <Icon as={FiDatabase} boxSize={5} color="orange.500" mr={2} />
        <Text fontSize="sm" fontWeight="medium">{label}</Text>
      </Flex>
      <Text fontSize="xl" fontWeight="bold">{value}</Text>
    </Box>
  );
};

export default ApiStatus;

